var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"pa-6 bg",staticStyle:{"font-family":"'Nunito Sans', sans-serif"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","xl12":""}},[_c('v-layout',{attrs:{"wrap":""}},[(_vm.appUser.usertype!=1 && this.BlockIds)?_c('v-flex',{attrs:{"xs2":"","text-left":""}},[(this.BlockIds.length>0)?_c('v-select',{staticClass:"rounded-xl",attrs:{"items":_vm.BlockIds,"label":"Select Block","item-text":"Block","item-value":"_id","hide-details":"","outlined":"","dense":""},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.hostelblockid),callback:function ($$v) {_vm.hostelblockid=$$v},expression:"hostelblockid"}}):_vm._e()],1):_vm._e()],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},_vm._l((_vm.card),function(card){return _c('v-flex',{key:card.name,staticClass:"text-left",staticStyle:{"font-family":"'Nunito Sans', sans-serif","font-size":"18px"},attrs:{"xs6":"","md2":"","sm3":"","lg2":"","align-self-start":"","pa-2":"","pa-sm-3":"","pa-lg-4":"","pa-xl-2":""}},[_c('v-layout',{attrs:{"justify-start":"","align-center":"","wrap":""}},[_c('v-flex',{attrs:{"align-self-start":"","px-xl-5":"","py-xl-4":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"to":card.route,"elevation":hover ? 12 : 2,"color":"white","hover":"","ripple":false,"height":_vm.$vuetify.breakpoint.name == 'xs' ? '190px' : '190px',"width":_vm.$vuetify.breakpoint.name == 'xl'
                        ? '100%'
                        : _vm.$vuetify.breakpoint.name == 'lg'
                        ? '100%'
                        : '152'}},[_c('v-layout',{attrs:{"wrap":"","pa-2":"","pa-md-4":"","align-center":"","text-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-start":""}},[_c('v-img',{attrs:{"width":"40px","contain":"","src":_vm.getImageURL(card.src)}})],1),_c('v-flex',{attrs:{"align-self-start":"","xs12":"","pt-2":"","text-left":""}},[_c('span',{staticClass:"title1 nsbold"},[_vm._v(_vm._s(card.name.slice(0, 50))+" ")]),_c('br')]),_c('v-flex',{attrs:{"align-self-start":"","text-left":"","xs12":"","pt-2":"","pt-sm-5":""}},[(card.name == 'Leave Request')?_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"35px"}},[_vm._v(" "+_vm._s(_vm.leaverequests)+" ")]):(card.name == 'Absent')?_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"35px"}},[(_vm.absentstudentcount)?_c('span',[_vm._v(" "+_vm._s(_vm.absentstudentcount)+" ")]):_c('span',[_vm._v(" 0 ")])]):(card.name == 'Present')?_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"35px"}},[(_vm.presentstudentcount)?_c('span',[_vm._v(" "+_vm._s(_vm.presentstudentcount)+" ")]):_c('span',[_vm._v(" 0 ")])]):(card.name == 'Outpass Issued')?_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"35px"}},[_vm._v(" "+_vm._s(_vm.outpassrequests)+" ")]):_c('span',{staticClass:"nsbold",staticStyle:{"font-size":"35px"}},[_vm._v(" "+_vm._s(_vm.visitrequests)+" ")])])],1)],1)]}}],null,true)})],1)],1)],1)}),1)],1)],1),(_vm.appUser.usertype == '1')?_c('v-layout',{attrs:{"pa-4":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":"","lg3":"","px-2":"","px-lg-0":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-card',{staticClass:"gradcard",attrs:{"height":"150px","elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.$router.push('/hostelMapping')}}},[_c('v-layout',{attrs:{"fill-height":"","align-content-center":"","wrap":"","white--text":""}},[_c('v-flex',{staticClass:"nsregular",staticStyle:{"font-size":"25px"},attrs:{"xs12":"","text-center":""}},[_c('v-icon',{staticClass:"pr-1 pb-1",staticStyle:{"font-size":"25px"},attrs:{"color":"white"}},[_vm._v("mdi-account-multiple-plus")]),_vm._v("Add Students To Hostel ")],1)],1)],1)]}}],null,false,616833582)})],1),_c('v-flex',{attrs:{"xs12":"","lg3":"","px-2":"","px-lg-0":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-card',{staticClass:"gradcard",attrs:{"height":"150px","elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.$router.push('/hostelStList')}}},[_c('v-layout',{attrs:{"fill-height":"","align-content-center":"","wrap":"","white--text":""}},[_c('v-flex',{staticClass:"nsregular",staticStyle:{"font-size":"25px"},attrs:{"xs12":"","text-center":""}},[_c('v-icon',{staticClass:"pr-1 pb-1",staticStyle:{"font-size":"25px"},attrs:{"color":"white"}},[_vm._v("mdi-account-details")]),_vm._v(" Hostel Students List ")],1)],1)],1)]}}],null,false,2077114682)})],1),_c('v-flex',{attrs:{"xs12":"","lg3":"","px-2":"","px-lg-0":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-card',{staticClass:"gradcard",attrs:{"height":"150px","elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.$router.push('/roomStrctr')}}},[_c('v-layout',{attrs:{"fill-height":"","align-content-center":"","wrap":"","white--text":""}},[_c('v-flex',{staticClass:"nsregular",staticStyle:{"font-size":"25px"},attrs:{"xs12":"","text-center":""}},[_c('v-icon',{staticClass:"pr-1 pb-1",staticStyle:{"font-size":"25px"},attrs:{"color":"white"}},[_vm._v("mdi-playlist-edit")]),_vm._v(" Room Allotment ")],1)],1)],1)]}}],null,false,1793210150)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }