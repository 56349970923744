<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout
          wrap
          justify-center
          class="pa-6 bg"
          style="font-family: 'Nunito Sans', sans-serif"
        >
          <!-- <v-flex xs12>
            <v-layout wrap>
             
              <v-flex xs7 pl-3 full-width white--text>
                <p style="font-size: 25px; font-weight: 800" class="my-0">
                  Dashboard
                </p>
                <p style="font-size: 18px; font-weight: 400">
                  Chavara Public School
                </p>
              </v-flex>
            </v-layout>
          </v-flex> -->
          <v-flex xs12 xl12>
            <v-layout wrap>
              <v-flex xs2 text-left v-if="appUser.usertype!=1 && this.BlockIds">
                 <v-select v-if="this.BlockIds.length>0"
                :items="BlockIds"
                v-model="hostelblockid"
                label="Select Block"
                class="rounded-xl"
                item-text="Block"
                item-value="_id"
                hide-details
                @input="getData()"
                outlined
                dense
              ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex
                xs6
                md2
                sm3
                lg2
                align-self-start
                v-for="card in card"
                :key="card.name"
                pa-2
                pa-sm-3
                pa-lg-4
                pa-xl-2
                class="text-left"
                style="font-family: 'Nunito Sans', sans-serif; font-size: 18px"
              >
                <v-layout justify-start align-center wrap>
                  <v-flex align-self-start px-xl-5 py-xl-4>
                     <v-hover v-slot="{ hover }">
                    <v-card
                      :to="card.route"
                      :elevation="hover ? 12 : 2"
                      color="white"
                      hover
                      :ripple="false"
                      :height="
                        $vuetify.breakpoint.name == 'xs' ? '190px' : '190px'
                      "
                      :width="
                        $vuetify.breakpoint.name == 'xl'
                          ? '100%'
                          : $vuetify.breakpoint.name == 'lg'
                          ? '100%'
                          : '152'
                      "
                    >
                      <!-- <v-card-text
                        style="overflow-y: auto; height: 180px; width: 170px"
                      > -->
                      <v-layout
                        wrap
                        pa-2
                        pa-md-4
                        align-center
                        text-center
                        fill-height
                      >
                        <v-flex xs12 align-self-start>
                          <v-img
                            width="40px"
                            contain
                            :src="getImageURL(card.src)"
                          ></v-img>
                        </v-flex>

                        <v-flex align-self-start xs12 pt-2 text-left>
                          <span class="title1 nsbold"
                            >{{ card.name.slice(0, 50) }} </span
                          ><br />
                           <!-- <span class="title1 nsbold"
                            >(Today)</span
                          ><br /> -->
                          <!-- <template v-if="presentstudentcount.length > 0">
                            <span
                              v-if="
                                card.name == 'Present' || card.name == 'Absent'
                              "
                            >
                              ({{
                                presentstudentcount[0].sessionId.name
                              }})</span
                            >
                          </template> -->
                        </v-flex>
                        <v-flex align-self-start text-left xs12 pt-2 pt-sm-5>
                          <span
                            v-if="card.name == 'Leave Request'"
                            style="font-size: 35px"
                            class="nsbold"
                          >
                            {{ leaverequests }}
                          </span>
                          <span
                            v-else-if="card.name == 'Absent'"
                            style="font-size: 35px"
                            class="nsbold"
                          >
                            <span v-if="absentstudentcount">
                              {{
                                absentstudentcount
                              }}
                            </span>
                            <span v-else> 0 </span>
                          </span>
                          <span
                            v-else-if="card.name == 'Present'"
                            style="font-size: 35px"
                            class="nsbold"
                          >
                            <span v-if="presentstudentcount">
                              {{
                                presentstudentcount
                              }}
                            </span>
                            <span v-else> 0 </span>
                          </span>
                          <span
                            v-else-if="card.name == 'Outpass Issued'"
                            style="font-size: 35px"
                            class="nsbold"
                          >
                            {{ outpassrequests }}
                          </span>
                          <span v-else style="font-size: 35px" class="nsbold">
                            {{ visitrequests }}
                          </span>
                        </v-flex>
                        <!-- <v-flex align-self-start text-left xs12 pt-2 pt-sm-5>
                          <span class="text1" :style="$vuetify.breakpoint.name == 'xs'?'font-size:12px':'' ">{{ card.title }}</span>
                        </v-flex> -->
                      </v-layout>
                      <!-- </v-card-text> -->
                    </v-card>
                     </v-hover>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
       <v-layout pa-4 justify-space-around v-if="appUser.usertype == '1'">
         <v-flex xs12 lg3 px-2 px-lg-0>
            <v-hover v-slot="{ hover }">
         
           <v-card
              height="150px" :elevation="hover ? 12 : 2"
              class="gradcard" 
              @click="$router.push('/hostelMapping')"
            >
              <v-layout fill-height align-content-center wrap white--text>
               <v-flex
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 25px"
                >
                  <v-icon class="pr-1 pb-1" color="white"
                  style="font-size: 25px;">mdi-account-multiple-plus</v-icon>Add Students To Hostel
                </v-flex>
              
              </v-layout>
            </v-card>
            </v-hover>
        </v-flex>
          <v-flex xs12 lg3 px-2 px-lg-0>
            <v-hover v-slot="{ hover }">
         
           <v-card
              height="150px" :elevation="hover ? 12 : 2"
              class="gradcard" 
              @click="$router.push('/hostelStList')"
            >
              <v-layout fill-height align-content-center wrap white--text>
               <v-flex
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 25px"
                ><v-icon class="pr-1 pb-1" color="white"
                  style="font-size: 25px;">mdi-account-details</v-icon>
                 Hostel Students List
                </v-flex>
              
              </v-layout>
            </v-card>
            </v-hover>
        </v-flex>
           <v-flex xs12 lg3 px-2 px-lg-0>
          <v-hover v-slot="{ hover }">
              <v-card
              height="150px"
              class="gradcard" 
              :elevation="hover ? 12 : 2"
              @click="$router.push('/roomStrctr')"
            >
              <v-layout fill-height align-content-center wrap white--text>
               <v-flex
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 25px"
                ><v-icon class="pr-1 pb-1" color="white"
                  style="font-size: 25px;">mdi-playlist-edit</v-icon>
                  Room Allotment
                </v-flex>
              
              </v-layout>
            </v-card>
          </v-hover>
        </v-flex>
       </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      count: {},
      today: new Date(),
      leaverequests: null,
      outpassrequests: null,
      presentstudentcount: null,
      absentstudentcount: null,
      visitrequests: null,
      pdate: null,
      a: null,
      hostelblockid:null,
      testid:null,
      BlockIds:[],

      card: [
        {
          name: "Leave Request",
          src: require("../../assets/images/student.png"),
          route: "/leaveRequest",
          //   name: "HostelWarden",
        },
        {
          name: "Absent",
          src: require("../../assets/images/noticeboard.png"),
                    route: "/todayAbsentList?id="+ this.testid, 
          //   name: "generateOutpass",
        },
        {
          name: "Present",
          src: require("../../assets/images/staff.png"),
          route: "/todayPresentList?id="+ this.testid,
          // route: "/todayPresent?id=" + this.pid,
          //   name: "outpassReport",
        },
        {
          name: "Outpass Issued",
          src: require("../../assets/images/clsndtchr.png"),
          route: "/outpassReport?stat=Active&curdate=" ,
          //   name: "outpassStudentList",
        },
        {
          name: "Visitor Request",
          src: require("../../assets/images/analytic.png"),
          route: "/visitorReport",
          //   name: "attendanceReport",
        },
      ],
    };
  },
  computed: {
    appLogin() {
      return store.state.email;
    },
    appUser() {
      return store.state.userData;
    },
    testblockId(){
      return store.state.blockId;
    },
  },
  // created() {
  //   this.appTimes()
  // },
  beforeMount(){
    this.getBlockid();

  },
  watch:{
    hostelblockid(){
      var vm = this;
    vm.testid=vm.hostelblockid;
    vm.card =  [
        {
          name: "Leave Request",
          src: require("../../assets/images/student.png"),
          route: "/leaveRequest",
          //   name: "HostelWarden",
        },
        {
          name: "Absent",
          src: require("../../assets/images/noticeboard.png"),
          route: "/todayAbsentList?id="+ this.testid,
          // route: "/todayAbsent",
          //   name: "generateOutpass",
        },
        {
          name: "Present",
          src: require("../../assets/images/staff.png"),
          route: "/todayPresentList?id="+ this.testid,
          // route: "/todayPresent?id=" + this.pid,
          //   name: "outpassReport",
        },
        {
          name: "Outpass Issued",
          src: require("../../assets/images/clsndtchr.png"),
          route: "/outpassReport?stat=Active&curdate=" ,
          //   name: "outpassStudentList",
        },
        {
          name: "Visitor Request",
          src: require("../../assets/images/analytic.png"),
          route: "/visitorReport",
          //   name: "attendanceReport",
        },
      ];
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getImageURL(src) {
      // let images = require.context("../../assets/images/");
      return src;
    },
getBlockid() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/warden/hostelblock/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            if(response.data.hosteldata){
              if(response.data.hosteldata.length>0){
           this.BlockIds = response.data.hosteldata;
           this.hostelblockid = response.data.hosteldata[0]._id;
           this.getData();
              }
              else{
           this.hostelblockid = "0";

              }
            }
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
          //  else {
          //   this.appTimes();
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    // appTimes() {
    //   this.today = new Date();
    //   this.today = JSON.stringify(this.today);
    //   if(this.presentstudentcount.length>0) {
    //     if (!this.presentstudentcount[0].sessionId && this.presentstudentcount[0].createddate) {
    //     this.card = [
    //       {
    //         name: "Leave Request",
    //         src: require("../../assets/images/student.png"),
    //         route: "/leaveRequest",
    //       },
    //       {
    //         name: "Absent",
    //         src: require("../../assets/images/noticeboard.png"),
    //         route:
    //           "/todayAbsent?pid=" +
    //           this.pid +
    //           "&session=null" +
    //           "&date=" +
    //           this.presentstudentcount[0].createddate,
    //           name: "generateOutpass",
    //       },
    //       {
    //         name: "Present",
    //         src: require("../../assets/images/staff.png"),
    //         route:
    //           "/todayPresent?pid=" +
    //           this.pid +
    //           "&session=null" +
    //           "&date=" +
    //           this.presentstudentcount[0].createddate,
    //           name: "outpassReport",
    //       },
    //       {
    //         name: "Outpass Issued",
    //         src: require("../../assets/images/clsndtchr.png"),
    //         route:
    //           "/outpassReport?stat=Active&curdate=" + this.today.slice(1, 11),
    //           name: "outpassStudentList",
    //       },
    //       {
    //         name: "Visitor Request",
    //         src: require("../../assets/images/analytic.png"),
    //         route:
    //           "/visitorReport?stat=Pending&curdate=" + this.today.slice(1, 11),
    //           name: "attendanceReport",
    //       },
    //     ];
    //   }
    //   else if(!this.presentstudentcount[0].createddate && this.presentstudentcount[0].sessionId){
    //     this.card = [
    //     {
    //       name: "Leave Request",
    //       src: require("../../assets/images/student.png"),
    //       route: "/leaveRequest",
    //       //   name: "HostelWarden",
    //     },
    //     {
    //       name: "Absent",
    //       src: require("../../assets/images/noticeboard.png"),
    //       // route:
    //       //   "/todayAbsent?pid=" +
    //       //   this.pid +
    //       //   "&session=" +
    //       //   this.presentstudentcount[0].sessionId.name +
    //       //   "&date=null",
    //       //   name: "generateOutpass",
    //     },
    //     {
    //       name: "Present",
    //       src: require("../../assets/images/staff.png"),
    //       // route:
    //       //   "/todayPresent?pid=" +
    //       //   this.pid +
    //       //   "&session=" +
    //       //   this.presentstudentcount[0].sessionId.name +
    //       //   "&date=null",
    //       //   name: "outpassReport",
    //     },
    //     {
    //       name: "Outpass Issued",
    //       src: require("../../assets/images/clsndtchr.png"),
    //       route:
    //         "/outpassReport?stat=Active&curdate=" + this.today.slice(1, 11),
    //       //   name: "outpassStudentList",
    //     },
    //     {
    //       name: "Visitor Request",
    //       src: require("../../assets/images/analytic.png"),
    //       route:
    //         "/visitorReport?stat=Pending&curdate=" + this.today.slice(1, 11),
    //       //   name: "attendanceReport",
    //     },
    //   ];
    //   }
    //    else if(this.presentstudentcount[0].createddate && this.presentstudentcount[0].sessionId){
        
        
    //     this.card = [
    //     {
    //       name: "Leave Request",
    //       src: require("../../assets/images/student.png"),
    //       route: "/leaveRequest",
    //       //   name: "HostelWarden",
    //     },
    //     {
    //       name: "Absent",
    //       src: require("../../assets/images/noticeboard.png"),
    //       // route:
    //       //   "/todayAbsent?pid=" +
    //       //   this.pid +
    //       //   "&session=" +
    //       //   this.presentstudentcount[0].sessionId.name +
    //       //   "&date=" +
    //       //   this.presentstudentcount[0].createddate,
    //       //   name: "generateOutpass",
    //     },
    //     {
    //       name: "Present",
    //       src: require("../../assets/images/staff.png"),
    //       // route:
    //       //   "/todayPresent?pid=" +
    //       //   this.pid +
    //       //   "&session=" +
    //       //   this.presentstudentcount[0].sessionId.name +
    //       //   "&date=" +
    //       //   this.presentstudentcount[0].createddate,
    //       //   name: "outpassReport",
    //     },
    //     {
    //       name: "Outpass Issued",
    //       src: require("../../assets/images/clsndtchr.png"),
    //       route:
    //         "/outpassReport?stat=Active&curdate=" + this.today.slice(1, 11),
    //       //   name: "outpassStudentList",
    //     },
    //     {
    //       name: "Visitor Request",
    //       src: require("../../assets/images/analytic.png"),
    //       route:
    //         "/visitorReport?stat=Pending&curdate=" + this.today.slice(1, 11),
    //       //   name: "attendanceReport",
    //     },
    //   ];
        
        
       
    //   }
    //   }
    //   else{
    //      this.card = [
    //     {
    //       name: "Leave Request",
    //       src: require("../../assets/images/student.png"),
    //       route: "/leaveRequest",
    //       //   name: "HostelWarden",
    //     },
    //     {
    //       name: "Absent",
    //       src: require("../../assets/images/noticeboard.png"),
    //       // route:
    //       //   "/todayAbsent?pid=" +
    //       //   this.pid +
    //       //   "&session=null" +
    //       //   "&date=null",
    //       //   name: "generateOutpass",
    //     },
    //     {
    //       name: "Present",
    //       src: require("../../assets/images/staff.png"),
    //       // route:
    //       //   "/todayPresent?pid=" +
    //       //   this.pid +
    //       //   "&session=null"   +
    //       //   "&date=null",
    //       //   name: "outpassReport",
    //     },
    //     {
    //       name: "Outpass Issued",
    //       src: require("../../assets/images/clsndtchr.png"),
    //       route:
    //         "/outpassReport?stat=Active&curdate=" + this.today.slice(1, 11),
    //       //   name: "outpassStudentList",
    //     },
    //     {
    //       name: "Visitor Request",
    //       src: require("../../assets/images/analytic.png"),
    //       route:
    //         "/visitorReport?stat=Pending&curdate=" + this.today.slice(1, 11),
    //       //   name: "attendanceReport",
    //     },
    //   ];
      
    //   }
    //    console.log("ok end")
    // },
    getData() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/dashboard/count/web",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          blockId:this.hostelblockid,
          // id: this.$route.query.id,
          // page: this.page,
          // limit: 20,
          // keyword: this.keyword,
          // fromDate: this.fromDate,
          // toDate: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.leaverequests = response.data.leaverequests;
            this.outpassrequests = response.data.outpassrequests;
 this.presentstudentcount = response.data.presentstudentcount;
  if(response.data.absentcount){
            if(response.data.absentcount.length>0){
            this.absentstudentcount = response.data.absentcount[0].totalAbsentCount;
            } 
            }
           
            this.a = response.data.visitrequests;

            // if (response.data.presentstudentcount.length > 0) {
            //   this.pid = response.data.presentstudentcount[0]._id;
            // }

            this.visitrequests = response.data.visitrequests;

            // this.appTimes();

            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            // this.appTimes();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
<style lang="css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700;800&display=swap");
.bg {
  /* background: url("../../assets/images/Asset2.png") 0% 0% no-repeat padding-box; */
  background: transparent
    /* linear-gradient(104deg, #8d84c4 10%, #9284EE 52%, #6156AD 100%) 0% 0% !important; */
     linear-gradient(140deg,#b8aeef 0%, #766BC0 72%, #6d5dda 100%) 0% 0% !important;
  opacity: 1;
}
.btn {
  text-transform: unset !important;
}

a {
  color: black;
  text-decoration: none;
}

.router-link-exact-active a {
  color: black;
}
.gradcard {
  background: transparent
    linear-gradient(104deg,#afa2ef 0%, #766BC0 72%, #6f60d6 100%) 0% 0% !important;
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}
</style>